@include media-breakpoint-up(lg) {
  .colour-range {
    .title-with-divider__title {
      text-align: left;
    }
  }

  .colour-cards {
    > .row {
      margin-left: $colour-cards-gutter-width-lg * -.5;
      margin-right: $colour-cards-gutter-width-lg * -.5;

      > [class*="col"] {
        padding-left: $colour-cards-gutter-width-lg / 2;
        padding-right: $colour-cards-gutter-width-lg / 2;
        margin-bottom: 0;
      }
    }
  }
}