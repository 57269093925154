.colour-cards {
  position: relative;  // to position SelectedColor component

  > .row {
    margin-left: $colour-cards-gutter-width * -.5;
    margin-right: $colour-cards-gutter-width * -.5;

    > [class*="col"] {
      padding-left: $colour-cards-gutter-width / 2;
      padding-right: $colour-cards-gutter-width / 2;
      margin-bottom: $colour-cards-gutter-width;
    }
  }
}