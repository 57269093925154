@include media-breakpoint-up(lg) {
  .landing-hero {
    height: 70vh;
    min-height: 22rem;
    display: flex;
    align-items: center;
  }

  .hero-content {
    padding: 0;
  }

  .hero-content__title {
    font-size: $h1-font-size * 1.5;
  }

  .hero-content__subtitle {
    font-size: $h1-font-size;
  }
}