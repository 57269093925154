.landing-hero {
  background: url("../img/background.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  color: white;
  margin-top: $header-height * -1;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.hero-content {
  position: relative;
  z-index: 1;
  padding: 5rem 0 4rem 0;
}

.hero-content__title,
.hero-content__subtitle {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
}

.hero-content__title {
  margin-bottom: 1rem;
}

.hero-content__subtitle {
  margin-bottom: 2rem;
}

.hero-content__button {

}
