.request-form {
  h2 {
    font-weight: $font-weight-semi-bold;
    font-size: $h4-font-size;
  }

  h3 {
    font-weight: $font-weight-normal;
    font-size: $h5-font-size;
  }
}

.maglia-form-wrapper {
  position: relative;  // to position FormSuccessLayer component
}

.request-maglia-form {
  padding-top: 2rem;

  .form-group {
    position: relative;
  }

  label {
    position: absolute;
    margin: 0;
    color: $orange;
    background-color: #FFF8EA;
    font-size: $font-size-sm;
    left: 10px;
    top: -9px;
    padding: 0 3px;
    height: 18px;
    line-height: 18px;
    opacity: 0;
    z-index: 10;
  }

  .form-group--filled {
    input,
    select {
      border-color: $orange;
    }

    label {
      opacity: 1;
    }
  }
}