@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-Bold.96c41886.woff2) format('woff2'),
        url(/static/media/GillSans-Bold.b9ff2592.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-SemiBold.c8daa9e0.woff2) format('woff2'),
        url(/static/media/GillSans-SemiBold.6dda6328.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-Light.71c721de.woff2) format('woff2'),
        url(/static/media/GillSans-Light.e1c9b16e.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-SemiBoldItalic.29c5ffe4.woff2) format('woff2'),
        url(/static/media/GillSans-SemiBoldItalic.cea1167d.woff) format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-LightItalic.75a2e052.woff2) format('woff2'),
        url(/static/media/GillSans-LightItalic.1b21165e.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans.2aadcbb7.woff2) format('woff2'),
        url(/static/media/GillSans.19c76bbe.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-UltraBold.0600d62d.woff2) format('woff2'),
        url(/static/media/GillSans-UltraBold.a49e106e.woff) format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-Italic.bd0b24fa.woff2) format('woff2'),
        url(/static/media/GillSans-Italic.47364633.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-BoldItalic.83d0d7ce.woff2) format('woff2'),
        url(/static/media/GillSans-BoldItalic.293ee33d.woff) format('woff');
    font-weight: bold;
    font-style: italic;
}


body {
  font-family: "Gill Sans", sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.btn-primary {
  color: #212529;
  background-color: #FF9900;
  border-color: #FF9900; }
  .btn-primary:hover {
    color: #fff;
    background-color: #d98200;
    border-color: #cc7a00; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 136, 6, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #FF9900;
    border-color: #FF9900; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7300; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 136, 6, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #024C77;
  border-color: #024C77; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #013451;
    border-color: #012c45; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 103, 139, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #024C77;
    border-color: #024C77; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #012c45;
    border-color: #012438; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 103, 139, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-primary {
  color: white; }

.maglia-header {
  height: 60px;
  text-align: left;
  position: relative;
  z-index: 1; }

.maglia-header-logo {
  height: 26px;
  margin: 17px 0; }

@media (max-width: 991.98px) {
  .maglia-header {
    text-align: center; } }

.landing-hero {
  background: url(/static/media/background.eb01a0ef.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  color: white;
  margin-top: -60px; }
  .landing-hero:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2); }

.hero-content {
  position: relative;
  z-index: 1;
  padding: 5rem 0 4rem 0; }

.hero-content__title,
.hero-content__subtitle {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.hero-content__title {
  margin-bottom: 1rem; }

.hero-content__subtitle {
  margin-bottom: 2rem; }

@media (min-width: 992px) {
  .landing-hero {
    height: 70vh;
    min-height: 22rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .hero-content {
    padding: 0; }
  .hero-content__title {
    font-size: 3.75rem; }
  .hero-content__subtitle {
    font-size: 2.5rem; } }

.specs-card {
  background: #F9F8F5; }

.specs-card__image {
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background-color: #f1f1f1;
  background-position: center center;
  background-size: cover; }

.specs-card__title {
  font-size: 1.25rem;
  padding: 1rem 1rem 0 1rem;
  color: #BBB834;
  font-weight: 600;
  margin: 0; }

.specs-card__description {
  padding: 1rem;
  text-align: justify;
  color: #535353; }

@media (min-width: 992px) {
  .specs-card {
    height: 100%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08); } }

.specs-icon {
  text-align: center;
  color: #88888A; }

.specs-icon__image {
  width: 25px;
  height: 25px;
  margin: 0 auto .5rem auto; }

.specs-icon__name {
  font-size: 0.74375rem;
  text-transform: uppercase; }

@media (min-width: 992px) {
  .specs-suitable-for {
    border-top: 1px solid #f1f1f1;
    margin-top: 4rem;
    padding-top: 3rem; } }

.title-with-divider__title {
  text-align: center;
  text-transform: uppercase; }

.title-with-divider__title--black {
  color: black; }

.title-with-divider__title--gray {
  color: #88888A; }

.title-with-divider__divider {
  width: 30%; }

@media (min-width: 992px) {
  .title-with-divider__divider {
    display: none; } }

.request-form h2 {
  font-weight: 600;
  font-size: 1.5rem; }

.request-form h3 {
  font-weight: 400;
  font-size: 1.25rem; }

.maglia-form-wrapper {
  position: relative; }

.request-maglia-form {
  padding-top: 2rem; }
  .request-maglia-form .form-group {
    position: relative; }
  .request-maglia-form label {
    position: absolute;
    margin: 0;
    color: #FF9900;
    background-color: #FFF8EA;
    font-size: 0.875rem;
    left: 10px;
    top: -9px;
    padding: 0 3px;
    height: 18px;
    line-height: 18px;
    opacity: 0;
    z-index: 10; }
  .request-maglia-form .form-group--filled input,
  .request-maglia-form .form-group--filled select {
    border-color: #FF9900; }
  .request-maglia-form .form-group--filled label {
    opacity: 1; }

@media (max-width: 991.98px) {
  #command-disclaimer {
    display: none; } }

.form-success-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 102%;
  border: 1px solid #25AE88;
  background-color: #ECECEC;
  border-radius: 10px; }
  .form-success-layer > div {
    height: 100%; }

.form-success-layer-info {
  padding: 0 2rem; }
  .form-success-layer-info h1 {
    margin-bottom: 0; }
  .form-success-layer-info img {
    width: 90px;
    margin: 1rem 0; }
  .form-success-layer-info h5 {
    margin-bottom: 0;
    color: #3D3D3D;
    font-weight: 300; }
    .form-success-layer-info h5.bold {
      font-weight: 600; }

.colour-cards {
  position: relative; }
  .colour-cards > .row {
    margin-left: -4px;
    margin-right: -4px; }
    .colour-cards > .row > [class*="col"] {
      padding-left: 4px;
      padding-right: 4px;
      margin-bottom: 8px; }

@media (min-width: 992px) {
  .colour-range .title-with-divider__title {
    text-align: left; }
  .colour-cards > .row {
    margin-left: -6px;
    margin-right: -6px; }
    .colour-cards > .row > [class*="col"] {
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 0; } }

.colour-card {
  border: 1px solid #D6D6D6;
  cursor: pointer;
  transition: opacity ease-out 100ms; }
  @media (prefers-reduced-motion: reduce) {
    .colour-card {
      transition: none; } }
  .colour-card:hover {
    opacity: .8; }

.colour-card__image {
  height: 0;
  padding-bottom: 80%;
  background-color: #f1f1f1;
  background: no-repeat center center;
  background-size: cover;
  position: relative; }
  .colour-card__image i.fa {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #343434; }

.colour-card__description {
  text-align: center;
  padding: .3rem 0; }
  .colour-card__description h6 {
    text-transform: uppercase;
    font-size: 0.74375rem;
    color: #717171;
    margin: 0; }

@media (min-width: 992px) {
  .colour-card {
    border: none; }
  .colour-card__image {
    padding-bottom: 75%; }
  .colour-card__description {
    padding: 1rem 0; }
    .colour-card__description h6 {
      font-weight: 600; } }

.selected-color {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: white no-repeat center center;
  background-size: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12); }

.selected-color__header {
  height: 70px;
  background-color: white;
  text-align: right; }
  .selected-color__header button {
    width: 70px;
    height: 70px;
    background: none;
    border: none;
    box-shadow: none;
    outline: none; }
    .selected-color__header button img {
      width: 24.5px;
      height: 24.5px;
      opacity: .7; }

.selected-color__footer {
  width: 100%;
  height: 98px;
  position: absolute;
  bottom: 0;
  background-color: white;
  z-index: 1; }
  .selected-color__footer h4 {
    text-align: center;
    margin: 0;
    color: #717171; }
  .selected-color__footer h5 {
    text-align: center;
    margin: 0;
    color: #717171; }

.landing-footer {
  background-color: #646266;
  color: white;
  padding: 3rem 0 2rem 0; }
  .landing-footer a {
    color: white; }
  .landing-footer h5 {
    font-size: 1rem;
    font-weight: 600; }
  .landing-footer .nav a {
    padding: .15rem 0; }

.footer-newsletter__subtitle {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5); }

.footer-newsletter__form input {
  background-color: rgba(255, 255, 255, 0.13);
  border: none;
  border-radius: 0; }
  .footer-newsletter__form input::-webkit-input-placeholder {
    color: #A1A1A1; }
  .footer-newsletter__form input::-ms-input-placeholder {
    color: #A1A1A1; }
  .footer-newsletter__form input::placeholder {
    color: #A1A1A1; }
  .footer-newsletter__form input:focus, .footer-newsletter__form input:active {
    background-color: rgba(255, 255, 255, 0.13);
    outline: none;
    box-shadow: none;
    color: white; }

.footer-newsletter__form button {
  background-color: #BBB834;
  border-color: #BBB834;
  border-radius: calc(1.5em + 0.75rem + 2px);
  padding-left: 2rem;
  padding-right: 2rem; }

.footer-social-links a {
  width: 33%;
  display: inline-block; }
  .footer-social-links a i {
    color: rgba(255, 255, 255, 0.5); }

.footer-logo-and-copy img {
  height: 25px;
  width: auto;
  opacity: .5; }

.footer-logo-and-copy p {
  padding-top: 5px;
  font-size: 0.65625rem;
  opacity: .5; }

@media (min-width: 992px) {
  .footer-social-links a {
    display: block;
    padding: .15rem 0; } }

@media (min-width: 992px) {
  .home-color-request {
    background-color: rgba(136, 136, 138, 0.1);
    padding: 4rem 0 2rem 0; } }

