.specs-icon {
  text-align: center;
  color: #88888A;
}

.specs-icon__image {
  width: 25px;
  height: 25px;
  margin: 0 auto .5rem auto;
}

.specs-icon__name {
  font-size: $font-size-sm * 0.85;
  text-transform: uppercase;
}